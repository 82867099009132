import { EditorSDK, TransactionError } from '@wix/platform-editor-sdk';
import { FlowAPI } from '@wix/yoshi-flow-editor';

const TOKEN = '';

export async function setPermissionsPage(editorSDK: EditorSDK, flowAPI: FlowAPI, appDefinitionId: string) {
  const { applicationId } = await editorSDK.document.tpa.app.getDataByAppDefId(TOKEN, appDefinitionId);
  const components = await editorSDK.document.tpa.app.getAllCompsByApplicationId(TOKEN, applicationId);
  const packagePickerPageId = components?.find((c) => c.type === 'TPA')?.pageId;
  if (packagePickerPageId) {
    const noPermissionsPageId = await editorSDK.document.siteMembers.getCustomNoPermissionsPageId(TOKEN);
    if (noPermissionsPageId !== packagePickerPageId) {
      flowAPI.fedops.interactionStarted('es_set_permissions_page_tx');
      await runAndWaitForApprovalWithRetry(editorSDK, () =>
        editorSDK.document.siteMembers.setCustomNoPermissionsPageId(TOKEN, { pageId: packagePickerPageId }),
      );
      flowAPI.fedops.interactionEnded('es_set_permissions_page_tx');
    }
  }
}

async function runAndWaitForApprovalWithRetry(editorSDK: EditorSDK, action: () => void) {
  try {
    await editorSDK.document.transactions.runAndWaitForApproval(TOKEN, action);
  } catch (e) {
    if (await editorSDK.document.transactions.isConflictError(TOKEN, e as TransactionError)) {
      await editorSDK.document.transactions.runAndWaitForApproval(TOKEN, action);
    } else {
      throw e;
    }
  }
}
